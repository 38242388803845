import Box from "@mui/material/Box";
import Blockquote from "components/Blockquote";
import DonateButton from "components/DonateButton";
import { Markdown } from "components/Markdown";

import * as React from "react";
import BlogLayout from "src/components/BlogLayout";
import Paragraph from "src/components/Paragraph";
import img1 from "src/images/community/finding1.jpg";

export default function Page() {
  return (
    <BlogLayout slug="finding-care-community">
      <Paragraph component="div">
        <Markdown>
          {`
## EOCP's Crossroads shelter, a place to build community

At EOCP, we know that each unhoused person, no matter their struggles with mental health or substance use, carries a unique and complex set of life stories with them. Our case management and counseling team at our Crossroads shelter welcomes each client by taking the time to **genuinely understand them for who they are holistically, and not just defining them by their difficulties**. 

From the beginning of their time with EOCP, we focus on creating pathways towards trust, a critical ingredient in any effective therapeutic relationship. Many clients arrive at EOCP feeling hesitant about the process of working with a counselor on making a change in their lives. At Crossroads, our counseling team understands the need for **a nurturing and client-centered approach to treatment, helping each person recognize that they're in the driver's seat of their own plan for care**, while serving as their guide and champion towards self-empowerment.

  `}
        </Markdown>
      </Paragraph>

      <Paragraph component="div">
        <Box
          component="img"
          src={img1}
          sx={{ width: "40%", float: "left" }}
          mr={3}
          mb={3}
        />
        <Markdown>
          {`
**Beginning the journey towards healing**

Laura Osborn, Substance Abuse Counselor at Crossroads, works with many clients in the shelter, the majority of whom are living with HIV/AIDS or those who have been recently discharged from Alameda County's Health System (AHS) and are in need of recuperative care.

After a couple days of settling in at Crossroads, clients meet with Laura. There, they find a safe and comforting space that understands and accepts their individual situations. From the very first one-on-one meeting, clients step into a decision-making role in their own treatment. **"The first session is about deeply listening, and asking their permission to ask about their substance use, as a way to establish a trusting and safe environment," shared Laura.** In that initial assessment, we learn about each client's experience with substance use, their mental health and the traumas they are working through, as well as their feelings and readiness about making a big life change.

That first appointment also focuses on the tailored care each person needs to get to a place of stability. If needed, we refer clients to receive inpatient care at a rehabilitation center, such as [Bonita House](https://bonitahouse.org/), [Amber House](https://www.bayareacs.org/crisis-residential-treatment/), or [Sally's Place](https://livelafamilia.org/services/mental-health/), where they can receive intensive medical and psychological support &mdash; and then return to EOCP when they are in a better place. 
  `}
        </Markdown>
      </Paragraph>
      <Blockquote component="div">
        Building a self-care practice
        <Markdown>{`

After an initial assessment, the second counseling session prepares clients to set a direction for their treatment, and helps them build a practice of self-care. EOCP shares and creates materials that they can continue to reference on their journey to mental and physical wellness &mdash; essentially, a co-created care plan. These materials discuss **important approaches for daily habits that keep them on track: Getting enough sleep, taking medication, eating healthy, staying active, identifying good boundaries, naming and recognizing triggers, and tools for helping oneself move through those triggers.**

After identifying their plan together, clients move onto a weekly schedule of one-on-one counseling, which help each person envision a long-term pathway to change. EOCP follows a Harm Reduction approach that supports clients to minimize the use of substances that have more severe health impacts and encourages healthy relationships and friendships with others who can support them through treatment. 

What anchors this journey? For all clients, Laura shared, **"I emphasize the importance of finding a purpose in life. If one has a purpose in life that feels fulfilling &mdash; no matter how small or large &mdash; it is easier to stay sober and focus on one's mental well-being."**`}</Markdown>
      </Blockquote>

      <Paragraph component="div">
        <Markdown>
          {`
**A community of support**

Group therapy sessions on Saturdays offer **an approachable atmosphere for clients to share their experiences and create a supportive bond with each other.** Indulgent snacks, treats, and coffee are an allure, but they also help with substance withdrawal symptoms. At the group, Laura invites each person to tell their story, and the others in the group offer advice or their own perspectives. They practice developing the ability to name their feelings with more specificity, which helps them grow insight into themselves and their relationship to substance use. 

At the end of each session, participants turn to who they're sitting next to, and share something affirming about them. "In the group, clients plant the seeds for meaningful and mutually supportive bonds," said Laura. **As they practice showing up for each other, group therapy helps to expand the circle of care beyond EOCP's counseling team, and create a community of understanding, connection, and belonging.**
          `}
        </Markdown>
      </Paragraph>
      <Blockquote component="div">
        Building a self-care practice
        <Markdown>{`


**What's next?**

Beyond the weekly Saturday therapy groups, EOCP is creating space for a clean and sober dorm at Crossroads, so that those who want to focus on changing their relationship to substance use can talk and help each other on similar journeys. A second group is in the works to focus specifically on preventing and recovering from relapse, to create a nurturing space where people can speak about their setbacks. 

For clients grappling with mental health conditions, **substance use isn't an isolated challenge, but often a way to self-soothe and cope with their overall unstable or difficult circumstances.** The experience of homelessness often exacerbates mental health or substance use issues, and we know that a holistic approach to treatment &mdash; one that invites clients to understand the relationship between those issues and build insights about themselves &mdash; is especially important. 


`}</Markdown>
      </Blockquote>
      <Paragraph>
        Join us at EOCP in supporting clients to build strong self-care
        practices, sustain their emotional and physical health, and move into
        their next stage of life.
      </Paragraph>
      <DonateButton />
    </BlogLayout>
  );
}
